@import './common';

@import './abstracts/variables';
@import './base/reset';
@import './base/visually-hidden';

@import './abstracts/animations';
@import './abstracts/mixins';
@import './abstracts/placeholders';

@import './base/typography';
@import './base/utils';

@import './components/buttons';

@import './layout/header';
@import './layout/hero';
@import './layout/burger-menu';
@import './layout/buy-now-modal';
@import './layout/products';
@import './layout/about';
@import './layout/more-modal';
@import './layout/advantages';
@import './layout/gallery';
@import './layout/customer-reviews';
@import './layout/contacts';
@import './layout/our-locations-modal';
@import './layout/franchise-modal';
@import './layout/footer';
@import './layout/arrow-up';
@import './layout/products-modal';
