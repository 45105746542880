*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

html {
  font-size: 10px;
}

ul {
  list-style: none;
}

h1, h2, h3, h4, h5, p, ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@keyframes ice-cream-shadow-spawn {
  0% {
    filter: drop-shadow(0 0 #fcfcfc);
  }

  100% {
    filter: drop-shadow(0 0 40px #fcfcfc);
  }
}

@keyframes ice-cream-shadow-flash {
  0% {
    filter: drop-shadow(0 0 40px #fcfcfc);
  }

  100% {
    filter: drop-shadow(0 0 10px #fcfcfc);
  }
}

@keyframes ice-cream-rotate {
  0% {
    opacity: 0;
    transform: scale(0%);
  }

  100% {
    opacity: 1;
    transform: scale(100%)rotate(2turn);
  }
}

@keyframes eye-close {
  0% {
    transform: scaleY(100%);
  }

  48% {
    transform: scaleY(100%);
  }

  50% {
    transform: scaleY(0%);
  }

  52% {
    transform: scaleY(100%);
  }

  100% {
    transform: scaleY(100%);
  }
}

@keyframes plate-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes gallery {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  43% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

body {
  letter-spacing: .04em;
  color: #131313;
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  line-height: 1.76;
}

.title-label {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.23;
}

@media screen and (min-width: 768px) {
  .title-label {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1200px) {
  .title-label {
    font-size: 3rem;
  }
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-family: Seymour One, sans-serif;
  font-size: 3rem;
  line-height: 1.27;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 3.4rem;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 3.6rem;
  }
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  background-color: #84a278;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0 32px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0 16px;
  }
}

.btn {
  color: #131313;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 1rem;
  outline: none;
  padding: 10px 32px;
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.29;
  display: inline-block;
}

.btn--underlined {
  color: #f8d24e;
  text-decoration: underline;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.btn--underlined:hover {
  color: #0a8817;
}

.btn--underlined:focus {
  color: #fcfcfc;
}

.btn--underlined:disabled {
  color: #ababab;
}

.btn--primary {
  background-color: #f8d24e;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), background-color .25s cubic-bezier(.4, 0, .2, 1);
}

.btn--primary:hover {
  background-color: #0a8817;
}

.btn--primary:focus {
  color: #fcfcfc;
  background-color: #0a8817;
}

.btn--primary:disabled {
  background-color: #ababab;
}

.btn--primary svg {
  fill: currentColor;
  pointer-events: none;
}

.btn--border {
  background-color: rgba(0, 0, 0, 0);
  border-color: #f8d24e;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.btn--border:hover {
  border-color: #0a8817;
}

.btn--border:focus {
  color: #fcfcfc;
  border-color: #0a8817;
}

.btn--border:disabled {
  border-color: #ababab;
}

.btn--border svg {
  fill: currentColor;
  pointer-events: none;
}

.page-header {
  z-index: 10;
  width: 100%;
  padding: 12px 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .page-header {
    padding: 20px 0;
  }
}

@media screen and (min-width: 1200px) {
  .page-header {
    padding: 18px 0;
  }
}

.header-content {
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .logo {
    margin-right: 270px;
  }
}

@media screen and (max-width: 1199px) {
  .header-nav {
    display: none;
  }
}

.header-nav__list {
  gap: 27px;
  display: flex;
}

.header-nav__link {
  color: #fcfcfc;
  letter-spacing: .1px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.25;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.header-nav__link:hover, .header-nav__link:focus {
  color: #f8d24e;
}

.header-content__buttons {
  align-items: center;
  gap: 32px;
  margin-left: auto;
  display: flex;
}

.btn--buy {
  align-items: center;
  gap: 7px;
  line-height: 1.21429;
  display: flex;
}

@media screen and (max-width: 767px) {
  .header__btn {
    display: none;
  }
}

.btn__icon {
  fill: currentColor;
}

.container--backgrounded {
  background: url("phone-left-balls@1x.74e77980.png") 0 100% / 110px 34px no-repeat, url("phone-right-balls@1x.846588b1.png") 100% 100% / 130px 48px no-repeat;
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .container--backgrounded {
    background: url("phone-left-balls@2x.dac8395e.png") 0 100% / 110px 34px no-repeat, url("phone-right-balls@2x.6d28dd2b.png") 100% 100% / 130px 48px no-repeat;
  }
}

@media screen and (min-width: 768px) {
  .container--backgrounded {
    background: url("tablet-left-balls@1x.321b53b1.png") 0 100% / 110px 34px no-repeat, url("tablet-right-balls@1x.4ee8814f.png") 100% 100% / 182px 67px no-repeat;
  }
}

@media screen and (min-width: 768px) and (min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2x) {
  .container--backgrounded {
    background: url("tablet-left-balls@2x.4a3e9020.png") 0 100% / 110px 34px no-repeat, url("tablet-right-balls@2x.e06bd57a.png") 100% 100% / 182px 67px no-repeat;
  }
}

@media screen and (min-width: 1200px) {
  .container--backgrounded {
    background: url("desktop-balls@1x.a4d37142.png") bottom / 1200px 384px no-repeat;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2x) {
  .container--backgrounded {
    background: url("desktop-balls@2x.01a3793c.png") bottom / 1200px 384px no-repeat;
  }
}

.hero {
  position: relative;
}

.hero__content {
  padding-top: 110px;
  padding-bottom: 239px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .hero__content {
    padding-top: 104px;
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1200px) {
  .hero__content {
    padding-top: 118px;
    padding-bottom: 65px;
  }
}

.hero__title {
  max-width: 250px;
  text-transform: uppercase;
  color: #fcfcfc;
  margin-bottom: 32px;
  font-family: Seymour One, sans-serif;
  font-size: 2.6rem;
  line-height: 1.26923;
}

@media screen and (min-width: 768px) {
  .hero__title {
    max-width: 182px;
    letter-spacing: normal;
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 1.27778;
  }
}

@media screen and (min-width: 1200px) {
  .hero__title {
    max-width: 367px;
    letter-spacing: 1.4px;
    margin-bottom: 28px;
    font-size: 3.8rem;
    line-height: 1.26316;
  }
}

.hero__accent-title {
  font-size: 3.4rem;
  line-height: 0;
}

@media screen and (min-width: 768px) {
  .hero__accent-title {
    font-size: 2.4rem;
    line-height: 1.26316;
  }
}

@media screen and (min-width: 1200px) {
  .hero__accent-title {
    font-size: 3.8rem;
  }
}

.hero__buttons {
  gap: 8px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .hero__buttons {
    margin-bottom: 28px;
  }
}

@media screen and (min-width: 1200px) {
  .hero__buttons {
    gap: 0;
    margin-bottom: 42px;
  }
}

.hero__btn {
  letter-spacing: normal;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: .8rem;
  line-height: 1.25;
  display: block;
}

@media screen and (min-width: 1200px) {
  .hero__btn {
    border-radius: 10px;
    padding: 10px 32px;
    font-size: 1.4rem;
    line-height: 1.28571;
  }
}

.hero__description {
  position: relative;
}

@media screen and (max-width: 767px) {
  .hero__description {
    display: none;
  }
}

.hero__plate {
  animation: plate-rotate 20s linear infinite;
}

.hero__text-wrapper {
  position: absolute;
  top: 28px;
  left: 185px;
}

@media screen and (min-width: 1200px) {
  .hero__text-wrapper {
    top: 68px;
    left: 239px;
  }
}

.hero__text {
  max-width: 151px;
  letter-spacing: normal;
  color: #fcfcfc;
  margin-bottom: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
}

@media screen and (min-width: 1200px) {
  .hero__text {
    max-width: 200px;
    margin-bottom: 24px;
    font-size: 1.4rem;
  }
}

.hero__link {
  display: block;
}

.hero__link:focus > .hero__arrow {
  transform: scale(120%);
}

.hero__arrow {
  fill: #fcfcfc;
  transform-origin: 0;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

.hero__arrow:hover {
  transform: scaleX(120%);
}

.hero__image-wrapper {
  min-width: 157px;
  position: absolute;
}

@media screen and (max-width: 767px) {
  .hero__image-wrapper {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 768px) {
  .hero__image-wrapper {
    animation: ice-cream-rotate 1s ease-in-out;
    bottom: 0;
    right: 61px;
  }
}

@media screen and (min-width: 1200px) {
  .hero__image-wrapper {
    right: 86px;
  }
}

.hero__img {
  animation: ice-cream-shadow-spawn 1s ease-in-out 1s forwards, ice-cream-shadow-flash 1s ease-in-out 2s infinite alternate;
}

.smiles {
  position: absolute;
  top: 0;
  left: 25px;
}

@media screen and (min-width: 768px) {
  .smiles {
    width: 214px;
    height: 240px;
    top: 4px;
    left: 45px;
  }
}

@media screen and (min-width: 1200px) {
  .smiles {
    width: 384px;
    height: 411px;
    top: 12px;
    left: 72px;
  }
}

.eye {
  animation: eye-close 5s ease-in-out infinite alternate;
  position: absolute;
  top: 24px;
  left: 94px;
}

@media screen and (min-width: 768px) {
  .eye {
    width: 34px;
    height: 38px;
    top: 40px;
    left: 155px;
  }
}

@media screen and (min-width: 1200px) {
  .eye {
    width: 62px;
    height: 66px;
    top: 68px;
    left: 268px;
  }
}

.statistics {
  position: absolute;
  top: 94px;
  right: 0;
}

@media screen and (max-width: 767px) {
  .statistics {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .statistics {
    top: 126px;
  }
}

.statistics__item {
  color: #fcfcfc;
  text-align: right;
  font-size: .8rem;
  line-height: 1.25;
}

.statistics__item:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (min-width: 1200px) {
  .statistics__item {
    font-size: 1.4rem;
    line-height: 1.21429;
  }

  .statistics__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.statistics__number {
  color: #131313;
  margin-bottom: 4px;
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 1.21429;
  display: block;
}

@media screen and (min-width: 1200px) {
  .statistics__number {
    font-size: 4.6rem;
    line-height: 1.21739;
  }
}

.burger {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
}

@media screen and (min-width: 1199px) {
  .burger {
    display: none;
  }
}

.burger__icon {
  fill: #fcfcfc;
}

.mobile-menu {
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: #84a278;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.mobile-menu.is-open {
  transform: translateX(0);
}

.mobile-menu__header {
  margin-bottom: 72px;
  padding: 12px 0;
  display: flex;
}

@media screen and (min-width: 768px) {
  .mobile-menu__header {
    padding: 20px 0;
  }
}

@media screen and (min-width: 1200px) {
  .mobile-menu__header {
    padding: 18px 0;
  }
}

.cross {
  fill: #fcfcfc;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-left: auto;
}

.mobile-nav {
  text-align: center;
  margin-bottom: 111px;
}

.mobile-nav__item:not(:last-child) {
  margin-bottom: 40px;
}

.mobile-nav__link {
  color: #fcfcfc;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.21875;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.mobile-nav__link:hover, .mobile-nav__link:focus {
  color: #131313;
}

@media screen and (min-width: 768px) {
  .mobile-nav__link {
    font-size: 4.8rem;
    line-height: 1.22917;
  }
}

.mobile-menu__btn {
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 30;
  background-color: rgba(10, 10, 10, .5);
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.backdrop.is-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .backdrop {
    padding: 20px;
  }
}

.modal {
  width: 280px;
  background: #fcfcfc;
  border: 2px solid #f8d24e;
  border-radius: 10px;
  margin: 0 auto;
  padding: 28px;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  transform: scale(1);
}

.backdrop.is-hidden .modal {
  transform: scale(1.1);
}

@media screen and (min-width: 768px) {
  .modal {
    width: 568px;
    padding: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(1);
  }

  .backdrop.is-hidden .modal {
    transform: translate(-50%, -50%)scale(1.1);
  }
}

.close-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media screen and (min-width: 768px) {
  .close-button {
    top: 20px;
    right: 20px;
  }
}

.close-button__icon {
  fill: #131313;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

.close-button__icon:hover, .close-button__icon:focus {
  transform: rotate(360deg);
}

.modal__title {
  text-transform: none;
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .modal__title {
    margin-bottom: 40px;
    font-size: 2rem;
    line-height: 1.4;
  }
}

.modal-list {
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .modal-list {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) {
  .modal-list {
    gap: 32px;
    margin-bottom: 40px;
  }
}

.modal-list__item {
  max-width: 132px;
  height: 135px;
  text-align: center;
  text-transform: uppercase;
  color: #131313;
  border: 2px solid #96af8c;
  border-radius: 15.7px;
  flex-basis: 100%;
  margin-top: 63px;
  padding: 95px 0 20px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.25;
  position: relative;
}

.modal-list__item:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -55px;
}

.modal-list__item:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -63px;
}

@media screen and (min-width: 768px) {
  .modal-list__item {
    flex-basis: calc(33.3333% - 21.3333px);
  }
}

.modal-list__item--green {
  border-color: #96af8c;
}

.modal-list__item--green:before {
  background: linear-gradient(0deg, rgba(156, 190, 143, .7), rgba(156, 190, 143, .7)), #d9d9d9;
  box-shadow: 0 5.26027px 19.726px rgba(10, 136, 23, .25);
}

.modal-list__item--green:after {
  background: url("ice-cream-one@1x.9d7f30bd.png") center / cover no-repeat;
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .modal-list__item--green:after {
    background: url("ice-cream-one@2x.207712a0.png") center / cover no-repeat;
  }
}

.modal-list__item--yellow {
  background-color: #efd478;
  border-color: #efd478;
}

.modal-list__item--yellow:before {
  background: linear-gradient(0deg, rgba(248, 210, 78, .7), rgba(248, 210, 78, .7)), #d9d9d9;
  box-shadow: 0 5.26027px 19.726px rgba(153, 114, 23, .25);
}

.modal-list__item--yellow:after {
  background: url("ice-cream-two@1x.fe3ca663.png") center / cover no-repeat;
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .modal-list__item--yellow:after {
    background: url("ice-cream-two@2x.d93a372c.png") center / cover no-repeat;
  }
}

.modal-list__item--brown {
  border-color: #d6936d;
}

.modal-list__item--brown:before {
  background: linear-gradient(0deg, rgba(213, 117, 63, .7), rgba(213, 117, 63, .7)), #d9d9d9;
  box-shadow: 0 5.26027px 19.726px rgba(0, 0, 0, .25);
}

.modal-list__item--brown:after {
  background: url("ice-cream-three@1x.199553a9.png") center / cover no-repeat;
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .modal-list__item--brown:after {
    background: url("ice-cream-three@2x.733bb5f6.png") center / cover no-repeat;
  }
}

.modal-form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.modal-form__input-wrapper {
  margin-bottom: 12px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .modal-form__input-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .modal-form__input-wrapper {
    margin-bottom: 16px;
  }
}

.modal-form__input-wrapper--textarea {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .modal-form__input-wrapper--textarea {
    margin-bottom: 32px;
  }
}

.modal-form__input {
  height: 32px;
  width: 100%;
  color: #131313;
  background-color: rgba(248, 210, 78, .1);
  border: 1px solid #84a278;
  border-radius: 10px;
  padding: 11px 16px;
  font-size: 1.4rem;
  line-height: 1.4;
  display: block;
}

.modal-form__input:focus {
  background-color: #f8ecbf;
  outline: rgba(0, 0, 0, 0);
}

.modal-form__input:not(:placeholder-shown):required:valid {
  border-color: green;
}

.modal-form__input:not(:placeholder-shown):required:invalid {
  border-color: red;
}

@media screen and (min-width: 768px) {
  .modal-form__input {
    height: 42px;
    width: 368px;
  }
}

.modal-form__label {
  color: #131313;
  transform-origin: 0;
  font-size: 1.2rem;
  line-height: 1.4;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1), font-size .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 8px;
  left: 12px;
}

.modal-form__input:focus + .modal-form__label, .modal-form__input:not(:placeholder-shown) + .modal-form__label {
  font-size: .8rem;
  transform: translate(-8px, -18px);
}

@media screen and (min-width: 768px) {
  .modal-form__label {
    font-size: 1.4rem;
    top: 11px;
    left: 16px;
  }

  .modal-form__input:focus + .modal-form__label, .modal-form__input:not(:placeholder-shown) + .modal-form__label {
    font-size: 1rem;
    transform: translate(-12px, -25px);
  }
}

.modal-form__input--textarea {
  resize: none;
  min-height: 84px;
}

@media screen and (max-width: 767px) {
  .modal__btn {
    font-size: 1.2rem;
  }
}

.products {
  background-color: #fff;
  background-image: url("backgr-mob-@1x.a3c987e7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products {
    background-image: url("backgr-mob@2x.3c5f9639.png");
  }
}

@media screen and (min-width: 768px) {
  .products {
    background-image: url("tab-backgr-1x-min.05b2de33.png");
    padding-bottom: 82px;
  }
}

@media screen and (min-width: 768px) and (min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2x) {
  .products {
    background-image: url("tab-backgr-@2x-min.7536b0aa.png");
  }
}

@media screen and (min-width: 1200px) {
  .products {
    background-image: url("deskt-backgr-1x-min.76841d46.png");
    padding-bottom: 66px;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2x) {
  .products {
    background-image: url("deskt-backgr@2x-min.270c83a0.png");
  }
}

.products__pre-title {
  margin-bottom: 12px;
}

.products__title {
  margin-bottom: 148px;
}

@media screen and (min-width: 768px) {
  .products__title {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .products__title {
    margin-bottom: 303px;
  }
}

.products__list {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 164px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .products__list {
    flex-direction: row;
    gap: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .products__list {
    gap: 32px;
  }
}

.products__item {
  width: 100%;
  min-height: 420px;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  padding: 123px 40px 6px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .products__item {
    width: calc(33.3333% - 10.6667px);
    min-height: 428px;
    padding-top: 106px;
    padding-left: 26px;
    padding-right: 26px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item {
    width: calc(33.3333% - 21.3333px);
    min-height: 535px;
    padding: 166px 63px 46px;
  }
}

.products__headings {
  letter-spacing: .04em;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 2rem;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .products__headings {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1200px) {
  .products__headings {
    margin-bottom: 65px;
    font-size: 3rem;
  }
}

.products__text {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .products__text {
    font-size: 1.6rem;
  }
}

.products__link {
  transform-origin: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  margin-top: auto;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.products__link:focus {
  outline: none;
  transform: scale(120%);
}

.products__link:hover {
  transform: scaleX(120%);
}

.products__arrow {
  transform-origin: 0;
  pointer-events: none;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.products__item--green {
  background-color: #aec6a5;
}

.products__item--green:before {
  content: "";
  width: 200px;
  height: 200px;
  background-color: #d9d9d9;
  background-image: linear-gradient(0deg, rgba(156, 190, 143, .7), rgba(156, 190, 143, .7)), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  position: absolute;
  top: -97px;
  box-shadow: 0 5.34219px 20.0332px rgba(10, 136, 23, .25);
}

@media screen and (min-width: 768px) {
  .products__item--green:before {
    top: -109px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--green:before {
    width: 300px;
    height: 300px;
    top: -166px;
    box-shadow: 0 8px 30px rgba(10, 136, 23, .25);
  }
}

.products__item--green:after {
  content: "";
  width: 201px;
  height: 200px;
  background-image: url("mob-ice-cream-1x-min.f38d1b51.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  align-content: center;
  align-items: center;
  display: inline-block;
  position: absolute;
  top: -108px;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--green:after {
    background-image: url("mob-ice-cream@2x-min.df50cef6.png");
  }
}

@media screen and (min-width: 768px) {
  .products__item--green:after {
    top: -120px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--green:after {
    width: 300px;
    height: 300px;
    background-image: url("desk-ice-cream1x-min.b26eced6.png");
    top: -183px;
  }
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--green:after {
    background-image: url("desk-ice-cream@2x-min.99657e24.png");
  }
}

.products__item--yellow {
  background-color: #efd478;
}

.products__item--yellow:before {
  content: "";
  width: 201px;
  height: 200px;
  background-color: #d9d9d9;
  background-image: linear-gradient(0deg, rgba(248, 210, 78, .7), rgba(248, 210, 78, .7)), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  align-content: center;
  display: inline-block;
  position: absolute;
  top: -97px;
  box-shadow: 0 5.40397px 20.2649px rgba(153, 114, 23, .25);
}

@media screen and (min-width: 768px) {
  .products__item--yellow:before {
    top: -109px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--yellow:before {
    width: 300px;
    height: 300px;
    top: -166px;
    box-shadow: 0 8px 30px rgba(153, 114, 23, .25);
  }
}

.products__item--yellow:after {
  content: "";
  width: 200px;
  height: 200px;
  background-image: url("mob-ice-coffe-1x-min.bfda9278.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  align-content: center;
  display: inline-block;
  position: absolute;
  top: -108px;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--yellow:after {
    background-image: url("mob-ice-coffe-@2x-min.d6e1da56.png");
  }
}

@media screen and (min-width: 768px) {
  .products__item--yellow:after {
    top: -120px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--yellow:after {
    width: 300px;
    height: 300px;
    background-image: url("desk-ice-coffe1x-min.977bd343.png");
    top: -183px;
  }
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--yellow:after {
    background-image: url("1desk-ice-coffe@2x-min.0c6708c6.png");
  }
}

.products__item--brown {
  background-color: #d6936d;
}

.products__item--brown:before {
  content: "";
  width: 200px;
  height: 200px;
  background-color: #d9d9d9;
  background-image: linear-gradient(0deg, rgba(213, 117, 63, .7), rgba(213, 117, 63, .7)), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  align-content: center;
  display: inline-block;
  position: absolute;
  top: -97px;
  box-shadow: 0 5.41333px 20.3px rgba(0, 0, 0, .25);
}

@media screen and (min-width: 768px) {
  .products__item--brown:before {
    top: -109px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--brown:before {
    width: 300px;
    height: 300px;
    top: -166px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, .25);
  }
}

.products__item--brown:after {
  content: "";
  width: 200px;
  height: 200px;
  background-image: url("mob-milkshake-1x-min.fd0c4857.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  align-content: center;
  align-items: center;
  display: inline-block;
  position: absolute;
  top: -108px;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--brown:after {
    background-image: url("mob-milkshake@2x-min.66e7d165.png");
  }
}

@media screen and (min-width: 768px) {
  .products__item--brown:after {
    top: -120px;
  }
}

@media screen and (min-width: 1200px) {
  .products__item--brown:after {
    width: 300px;
    height: 300px;
    background-image: url("desk-milkshake1x-min.36bff018.png");
    top: -183px;
  }
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .products__item--brown:after {
    background-image: url("desk-milkshake@2x-min.d801d46e.png");
  }
}

.about {
  color: #fcfcfc;
  padding-top: 60px;
}

.about__title-label {
  margin-bottom: 12px;
}

@media screen and (min-width: 1200px) {
  .about__title-label {
    letter-spacing: .04em;
  }
}

.about__title {
  max-width: 200px;
  color: #fcfcfc;
  margin: 0 auto 35px;
}

@media screen and (min-width: 768px) {
  .about__title {
    max-width: 100%;
    letter-spacing: .06em;
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .about__title {
    margin-bottom: 84px;
  }
}

.about__content {
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__content {
    flex-wrap: nowrap;
    align-items: center;
    gap: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .about__content {
    justify-content: left;
    gap: 108px;
  }
}

.about__image-wrap {
  max-width: 335px;
}

@media screen and (min-width: 480px) {
  .about__image-wrap {
    max-width: 335px;
  }
}

@media screen and (min-width: 768px) {
  .about__image-wrap {
    max-width: calc(50% - 16px);
  }
}

@media screen and (min-width: 1200px) {
  .about__image-wrap {
    max-width: 490px;
    width: 490px;
  }
}

.about__image {
  width: 100%;
  border-radius: 1038.58px;
  margin-top: 21.82px;
  box-shadow: 0 -21.82px #f8d24e;
}

@media screen and (min-width: 768px) {
  .about__image {
    margin-top: 25.88px;
    box-shadow: 0 -25.88px #f8d24e;
  }
}

@media screen and (min-width: 1200px) {
  .about__image {
    margin-top: 39px;
    box-shadow: 0 -39px #f8d24e;
  }
}

.about__btn-text-wrap {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

@media screen and (min-width: 480px) {
  .about__btn-text-wrap {
    max-width: 380px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .about__btn-text-wrap {
    max-width: calc(50% - 16px);
    text-align: left;
    align-items: baseline;
    gap: 0;
  }
}

@media screen and (min-width: 1200px) {
  .about__btn-text-wrap {
    max-width: 463px;
  }
}

.about__paragraph {
  color: #fcfcfc;
  font-size: 1.2rem;
}

@media screen and (max-width: 479px) {
  .about__paragraph:nth-child(2) {
    letter-spacing: .02em;
    line-height: 1.63;
  }
}

.about__paragraph:first-child {
  margin-bottom: 4px;
}

@media screen and (max-width: 767px) {
  .about__paragraph:last-child {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .about__paragraph:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 768px) {
  .about__paragraph:last-child {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .about__paragraph {
    font-size: 1.6rem;
  }

  .about__paragraph:not(:first-child) {
    font-size: 1.4rem;
  }
}

.about__btn {
  align-items: center;
  gap: 5px;
  display: flex;
}

.about-modal__title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .about-modal__title {
    margin-bottom: 32px;
  }
}

.about__video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .about__video {
    margin-bottom: 32px;
  }
}

.about__video-descr {
  letter-spacing: 0;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .about__video-descr {
    font-size: 1.6rem;
  }
}

.about-modal__title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .about-modal__title {
    margin-bottom: 32px;
  }
}

.about__video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .about__video {
    margin-bottom: 32px;
  }
}

.about__video-descr {
  letter-spacing: 0;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .about__video-descr {
    font-size: 1.6rem;
  }
}

.advantages {
  color: #fcfcfc;
  letter-spacing: 0;
  padding-top: 42px;
  padding-bottom: 36px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.7;
}

@media screen and (min-width: 768px) {
  .advantages {
    padding-top: 48px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .advantages {
    padding-top: 60px;
    padding-bottom: 84px;
  }
}

.advantages__list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .advantages__list {
    flex-wrap: nowrap;
    gap: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .advantages__list {
    gap: 32px;
  }
}

.advantages__item {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .advantages__item {
    width: calc(33.3333% - 82.6667px);
  }
}

@media screen and (min-width: 1200px) {
  .advantages__item {
    width: calc(33.3333% - 21.3333px);
  }
}

.advantages__icon {
  width: 50px;
  height: 50px;
  background-color: #f8d24e;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .advantages__icon {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .advantages__icon {
    margin-bottom: 40px;
  }
}

.advantages__subtitle {
  margin-bottom: 1.8rem;
  font-size: 36px;
}

@media screen and (min-width: 768px) {
  .advantages__subtitle {
    margin-bottom: 16px;
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1200px) {
  .advantages__subtitle {
    margin-bottom: 8px;
    font-size: 5.8rem;
  }
}

@media screen and (max-width: 767px) {
  .advantages__text {
    max-width: 312px;
  }
}

@media screen and (min-width: 768px) {
  .advantages__text {
    font-size: 1.4rem;
    font-weight: inherit;
  }
}

@media screen and (min-width: 1200px) {
  .advantages__text {
    max-width: 342px;
    margin: 0 auto;
    font-size: 1.6rem;
  }
}

.gallery__list {
  background-color: #fcfcfc;
  border-radius: 7.5px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .gallery__list {
    border-radius: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .gallery__list {
    max-width: 1013px;
    border-radius: 24px;
    margin: 0 auto;
  }
}

.gallery__item:nth-child(n+2) {
  position: absolute;
  top: 0;
}

.gallery__item {
  animation-name: gallery;
  animation-duration: 15s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.gallery__item:nth-child(2) {
  animation-delay: 5s;
}

.gallery__item:first-child {
  animation-delay: 10s;
}

.gallery-reviews-wrapper {
  min-height: 1070.26px;
  background-color: #fff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 320px) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 24px, rgba(0, 0, 0, 0) 24px), url("mb-bg@1x.5d5c2215.png");
  }
}

@media screen and (max-width: 320px) and (min-device-pixel-ratio: 2), screen and (max-width: 320px) and (min-resolution: 192dpi), screen and (max-width: 320px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 24px, rgba(0, 0, 0, 0) 24px), url("mb-bg@2x.7a6a09c3.png");
  }
}

@media screen and (min-width: 321px) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 24px, rgba(0, 0, 0, 0) 24px), url("tb-bg@1x.8ec1bab1.png");
    background-size: cover;
  }
}

@media screen and (min-width: 321px) and (min-device-pixel-ratio: 2), screen and (min-width: 321px) and (min-resolution: 192dpi), screen and (min-width: 321px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 24px, rgba(0, 0, 0, 0) 24px), url("tb-bg@2x.bf174d83.png");
  }
}

@media screen and (width: 768px) {
  .gallery-reviews-wrapper {
    min-height: 1122px;
    background-image: linear-gradient(#84a278, #84a278 164px, rgba(0, 0, 0, 0) 164px), url("tb-bg@1x.8ec1bab1.png");
    background-size: contain;
  }
}

@media screen and (width: 768px) and (min-device-pixel-ratio: 2), screen and (width: 768px) and (min-resolution: 192dpi), screen and (width: 768px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 164px, rgba(0, 0, 0, 0) 164px), url("tb-bg@2x.bf174d83.png");
  }
}

@media screen and (min-width: 769px) {
  .gallery-reviews-wrapper {
    min-height: 1122px;
    background-image: linear-gradient(#84a278, #84a278 164px, rgba(0, 0, 0, 0) 164px), url("dt-bg@1x.13b06418.png");
    background-size: cover;
  }
}

@media screen and (min-width: 769px) and (min-device-pixel-ratio: 2), screen and (min-width: 769px) and (min-resolution: 192dpi), screen and (min-width: 769px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 164px, rgba(0, 0, 0, 0) 164px), url("dt-bg@2x.d1b110ab.png");
  }
}

@media screen and (width: 1200px) {
  .gallery-reviews-wrapper {
    min-height: 1110px;
    background-image: linear-gradient(#84a278, #84a278 95px, rgba(0, 0, 0, 0) 95px), url("dt-bg@1x.13b06418.png");
    background-size: contain;
  }
}

@media screen and (width: 1200px) and (min-device-pixel-ratio: 2), screen and (width: 1200px) and (min-resolution: 192dpi), screen and (width: 1200px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 95px, rgba(0, 0, 0, 0) 95px), url("dt-bg@2x.d1b110ab.png");
  }
}

@media screen and (min-width: 1201px) {
  .gallery-reviews-wrapper {
    min-height: 1110px;
    background-image: linear-gradient(#84a278, #84a278 95px, rgba(0, 0, 0, 0) 95px), url("screen-bg@1x.f637bbef.png");
    background-size: cover;
  }
}

@media screen and (min-width: 1201px) and (min-device-pixel-ratio: 2), screen and (min-width: 1201px) and (min-resolution: 192dpi), screen and (min-width: 1201px) and (min-resolution: 2x) {
  .gallery-reviews-wrapper {
    background-image: linear-gradient(#84a278, #84a278 95px, rgba(0, 0, 0, 0) 95px), url("screen-bg@2x.5fbfe8da.png");
  }
}

.customer-reviews {
  text-align: center;
  padding-top: 80px;
}

@media screen and (min-width: 768px) {
  .customer-reviews {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .customer-reviews {
    padding-top: 128px;
  }
}

.avatar-container {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin: 0 auto 32px;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .avatar-container {
    margin-bottom: 28px;
  }
}

.customer-reviews__item {
  flex-direction: column;
  display: flex;
}

.customer-reviews__avatar {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

.customer-reviews__review {
  min-width: 280px;
  color: #fcfcfc;
  background-color: #84a278;
  border-radius: 1rem;
  flex-grow: 1;
  margin: 0 auto 30px;
  padding: 34px 39px;
  font-size: 12px;
  line-height: 2.55;
}

@media screen and (min-width: 768px) {
  .customer-reviews__review {
    border-radius: 2.4rem;
    margin-bottom: 32px;
    padding: 29px 80px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.86;
  }
}

@media screen and (min-width: 1200px) {
  .customer-reviews__review {
    width: 968px;
    padding: 39px 109px;
    font-size: 16px;
  }
}

.customer-reviews__meta {
  margin-bottom: 14px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.86;
}

@media screen and (min-width: 768px) {
  .customer-reviews__meta {
    margin-bottom: 32px;
    font-size: 1.6rem;
  }
}

.customer-reviews__pagination {
  justify-content: center;
  gap: 14px;
  display: flex;
  position: static;
}

.customer-reviews__pagination span {
  width: 12px;
  height: 12px;
  background-color: #84a278;
  border-radius: 50%;
}

.swiper-slide {
  height: auto;
}

.contacts {
  padding-top: 1028px;
  padding-bottom: 40px;
  position: relative;
}

@media screen and (min-width: 320px) {
  .contacts {
    padding-top: 962px;
  }
}

@media screen and (min-width: 768px) {
  .contacts {
    padding-top: 284px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts {
    padding-top: 506px;
    padding-bottom: 120px;
  }
}

.contacts__list {
  flex-direction: column;
  gap: 20px;
  display: flex;
  position: absolute;
  top: -346px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  .contacts__list {
    flex-direction: row;
    gap: 16px;
    top: -303px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts__list {
    gap: 32px;
    top: -96px;
  }
}

.contacts__item {
  width: calc(100vw - 40px);
  background-color: #fcfcfc;
  border-radius: 24px;
  padding: 38px 32px 40px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.86;
  box-shadow: 0 8px 30px rgba(212, 20, 67, .1);
}

@media screen and (min-width: 320px) {
  .contacts__item {
    width: 280px;
  }
}

@media screen and (min-width: 768px) {
  .contacts__item {
    width: 224px;
    padding: 40px 24px 36px;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1200px) {
  .contacts__item {
    width: 368px;
    padding: 40px 40px 32px;
    font-size: 1.6rem;
  }
}

.contacts__category {
  min-width: 80px;
  text-align: center;
  background-color: #f8d24e;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 20.5px;
  line-height: 1.33;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .contacts__category {
    min-width: 75px;
    border-radius: 6px;
    margin-bottom: 32px;
    padding: 0 11px;
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

.contacts__address {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 22px;
  padding-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .contacts__address {
    margin-bottom: 32px;
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts__address {
    padding-bottom: 23px;
  }
}

.contacts__address-link {
  color: #d6936d;
  margin-top: 8px;
  line-height: 1.83;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .contacts__address-link {
    margin-top: 12px;
    line-height: 1.87;
  }
}

.contacts__address-link:hover, .contacts__address-link:focus {
  text-decoration: underline;
}

.contacts__item:first-of-type .contacts__address-link {
  max-width: 192px;
}

@media screen and (min-width: 1200px) {
  .contacts__item:first-of-type .contacts__address-link {
    max-width: 262px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .contacts__item:nth-of-type(2) .contacts__address-link {
    max-width: 158px;
  }
}

.contacts__item:nth-of-type(3) .contacts__address-link {
  max-width: 185px;
}

@media screen and (min-width: 1200px) {
  .contacts__item:nth-of-type(3) .contacts__address-link {
    max-width: 261px;
  }
}

.contacts__work-time {
  letter-spacing: normal;
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .contacts__work-time {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts__work-time {
    letter-spacing: .04em;
    margin-bottom: 13px;
  }
}

.contacts__work-time:nth-last-of-type(1) {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .contacts__work-time:nth-last-of-type(1) {
    margin-bottom: 33px;
    padding-bottom: 33px;
  }
}

@media screen and (min-width: 1200px) {
  .contacts__work-time:nth-last-of-type(1) {
    margin-bottom: 31px;
    padding-bottom: 31px;
  }
}

.contacts__work-time > span {
  color: #ababab;
  letter-spacing: normal;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .contacts__work-time > span {
    line-height: 1.5;
  }
}

.contacts__email, .contacts__tel {
  letter-spacing: normal;
  line-height: 1.5;
  display: block;
}

@media screen and (min-width: 768px) {
  .contacts__email, .contacts__tel {
    line-height: 1.83;
  }
}

.contacts__btn-list {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .contacts__btn-list {
    flex-direction: row;
    justify-content: center;
  }
}

.contacts__btn {
  min-width: 196px;
  justify-content: center;
  align-items: center;
  gap: 11px;
  display: flex;
}

.contacts_btn-icon {
  fill: currentColor;
}

.modal__locations-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.modal__locations-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .modal__locations-title {
    margin-bottom: 32px;
  }
}

.modal__locations-map {
  width: 224px;
  height: 199px;
  border-radius: 10px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .modal__locations-map {
    width: 448px;
    height: 260px;
    margin-bottom: 32px;
  }
}

.modal__franchise-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.modal__franchise-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .modal__franchise-title {
    max-width: 322px;
    gap: 10px;
    margin-bottom: 24px;
  }
}

.modal__franchise-text {
  letter-spacing: normal;
  margin-bottom: 24px;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .modal__franchise-text {
    margin-bottom: 32px;
    padding: 0 24px;
    font-size: 1.6rem;
  }
}

html {
  overflow-y: scroll;
}

.modal__locations-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.modal__locations-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .modal__locations-title {
    margin-bottom: 32px;
  }
}

.modal__locations-map {
  width: 224px;
  height: 199px;
  border-radius: 10px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .modal__locations-map {
    width: 448px;
    height: 260px;
    margin-bottom: 32px;
  }
}

.modal__franchise-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.modal__franchise-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .modal__franchise-title {
    max-width: 322px;
    margin-bottom: 24px;
  }
}

.modal__franchise-text {
  letter-spacing: normal;
  margin-bottom: 24px;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .modal__franchise-text {
    margin-bottom: 32px;
    padding: 0 24px;
    font-size: 1.6rem;
  }
}

.footer {
  padding-bottom: 62px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .footer {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    padding-bottom: 10px;
  }
}

.footer:after {
  content: "";
  height: 1px;
  width: 100%;
  max-width: 1168px;
  background-color: #e1e1e1;
  display: block;
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  .footer:after {
    bottom: 53px;
  }
}

@media screen and (min-width: 1200px) {
  .footer:after {
    bottom: 45px;
  }
}

.footer__container {
  margin-bottom: 56px;
}

@media screen and (min-width: 768px) {
  .footer__container {
    justify-content: space-between;
    margin-bottom: 46px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .footer__container {
    margin-bottom: 66px;
  }
}

.footer__title {
  max-width: 260px;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #fcfcfc;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.22;
}

@media screen and (min-width: 768px) {
  .footer__title {
    max-width: 352px;
    letter-spacing: .04em;
    font-size: 2.6rem;
    line-height: 1.23;
  }
}

@media screen and (min-width: 1200px) {
  .footer__title {
    max-width: none;
    font-size: 3rem;
    font-weight: 400;
  }
}

.footer__title > span {
  letter-spacing: .04em;
  margin-top: 14px;
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.21;
  display: block;
}

@media screen and (min-width: 768px) {
  .footer__title > span {
    letter-spacing: .06em;
    margin-top: 2px;
    font-size: 4.8rem;
    line-height: 1.23;
  }
}

@media screen and (min-width: 1200px) {
  .footer__title > span {
    margin-top: 14px;
    font-size: 5.8rem;
    line-height: 1.22;
  }
}

.footer__social-list {
  justify-content: flex-end;
  gap: 29px;
  margin-top: 36px;
  margin-bottom: 38px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer__social-list {
    gap: 30px;
    margin-top: 68px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .footer__social-list {
    margin-top: 12px;
    margin-bottom: 40px;
  }
}

.footer__social-item {
  border-radius: 50%;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
  transform: scale(1);
}

.footer__social-item:focus-within {
  transform: scale(1.12);
}

.footer__social-link {
  color: #84a278;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.footer__social-link:hover, .footer__social-link:focus {
  color: #fcfcfc;
  outline: none;
}

.footer__social-icon {
  width: 32px;
  height: 32px;
  fill: currentColor;
  display: block;
}

@media screen and (min-width: 768px) {
  .footer__social-icon {
    width: 34px;
    height: 34px;
  }
}

.footer__contact {
  text-align: right;
  letter-spacing: normal;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.86;
}

@media screen and (min-width: 768px) {
  .footer__contact {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1200px) {
  .footer__contact {
    letter-spacing: .02em;
    font-size: 1.6rem;
  }
}

.footer__contact > .footer__contact-link {
  color: #fcfcfc;
}

.footer__copyright {
  text-align: center;
  color: #7a7a7a;
  letter-spacing: normal;
  margin: 0 auto;
  font-weight: 700;
  line-height: 1.52;
}

@media screen and (max-width: 399px) {
  .footer__copyright {
    max-width: 165px;
  }
}

@media screen and (min-width: 768px) {
  .footer__copyright {
    letter-spacing: .02em;
    line-height: 1.86;
  }
}

@media screen and (min-width: 1200px) {
  .footer__copyright {
    font-size: 1.2rem;
  }
}

.arrow-up {
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: .7;
  background-color: #fcfcfc;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1), opacity .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  bottom: 95px;
  right: 30px;
  transform: scale(1);
  box-shadow: 0 1px 2px rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.arrow-up:hover {
  color: #f8d24e;
  opacity: 1;
  transform: scale(1.1);
}

.arrow-up.hide {
  display: none;
}

.arrow-up-icon {
  fill: currentColor;
  transform: rotate(-90deg);
}

.modal__image {
  width: 131px;
  height: 131px;
  background: linear-gradient(0deg, rgba(156, 190, 143, .7), rgba(156, 190, 143, .7)), #d9d9d9;
  border-radius: 50%;
  margin: 0 auto 25px;
  display: block;
  position: relative;
}

.modal__image:after {
  content: "";
  width: 131px;
  height: 131px;
  background-image: url("modal-mob-icecr-1x-min.0164fe48.png");
  border-radius: 50%;
  position: absolute;
  top: -7px;
  box-shadow: 0 5.26027px 19.726px rgba(10, 136, 23, .25);
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .modal__image:after {
    background-image: url("modal-mob-icecr@2x-min.03030d3b.png");
    background-size: contain;
  }
}

.modal__image--coffee {
  width: 131px;
  height: 131px;
  background: linear-gradient(0deg, #efd478, #efd478), #d9d9d9;
  border-radius: 50%;
  margin: 0 auto 25px;
  display: block;
  position: relative;
}

.modal__image--coffee:after {
  content: "";
  width: 131px;
  height: 131px;
  background-image: url("mob-ice-coffe-1x-min.bfda9278.png");
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  box-shadow: 0 5.26027px 19.726px #efd478;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .modal__image--coffee:after {
    background-image: url("mob-ice-coffe-@2x-min.d6e1da56.png");
    background-size: contain;
  }
}

.modal__image--milkshakes {
  width: 131px;
  height: 131px;
  background: linear-gradient(0deg, #d6936d, #d6936d), #d9d9d9;
  border-radius: 50%;
  margin: 0 auto 25px;
  display: block;
  position: relative;
}

.modal__image--milkshakes:after {
  content: "";
  width: 131px;
  height: 131px;
  background-image: url("mob-milkshake-1x-min.fd0c4857.png");
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  box-shadow: 0 5.26027px 19.726px #d6936d;
}

@media screen and (min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2x) {
  .modal__image--milkshakes:after {
    background-image: url("mob-milkshake@2x-min.66e7d165.png");
  }
}

.modal-products-list {
  justify-content: center;
  gap: 9px;
  margin-bottom: 39px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .modal-products-list {
    gap: 20px;
  }
}

.modal-products-list__item {
  width: 53px;
  min-height: 72px;
  border: 2px solid #9cbe8f;
  border-radius: 40px;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 8px;
  font-size: 1rem;
  line-height: 1.2;
  display: flex;
  position: relative;
}

.modal-products-list__icons-text {
  width: 43px;
  height: 43px;
  background: #9cbe8f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 2.8;
  display: flex;
  position: absolute;
  top: -1px;
  box-shadow: 0 6px 20px rgba(10, 136, 23, .25);
}

@media screen and (min-width: 768px) {
  .modal-products-list__icons-text {
    box-shadow: 0 6px 20px rgba(0, 0, 0, .25);
  }
}

.modal-products__content {
  text-align: center;
  margin-bottom: 24px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.4;
}

.modal-products-elements {
  flex-direction: column;
  gap: 11px;
  margin-bottom: 48px;
  display: flex;
}

.modal-products-elements__item {
  justify-content: space-between;
  line-height: 1.9;
  display: flex;
}

/*# sourceMappingURL=index.33110450.css.map */
