.burger {
  border: none;
  padding: 0;
  background-color: transparent;

  cursor: pointer;

  @media screen and (min-width: 1199px) {
    display: none;
  }
}

.burger__icon {
  fill: $light-text-color;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $accent-color;

  z-index: 20;
  transform: translateX(100%);
  transition: transform $transition-duration $transition-timing-function;

  &.is-open {
    transform: translateX(0);
  }
}

.mobile-menu__header {
  display: flex;
  margin-bottom: 72px;
  padding: 12px 0;

  @media screen and (min-width: $tablet-width) {
    padding: 20px 0;
  }

  @media screen and (min-width: $desktop-width) {
    padding: 18px 0;
  }
}

.cross {
  margin-left: auto;
  border: none;
  background-color: transparent;
  fill: $light-text-color;

  cursor: pointer;
}

.mobile-nav {
  text-align: center;
  margin-bottom: 111px;
}

.mobile-nav__item {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.mobile-nav__link {
  font-size: 3.2rem;
  font-weight: $bold-text;
  color: $light-text-color;
  line-height: calc(39 / 32);
  transition: color $transition-duration $transition-timing-function;

  &:hover,
  &:focus {
    color: $default-text-color;
  }

  @media screen and (min-width: $tablet-width) {
    font-size: 4.8rem;
    line-height: calc(59 / 48);
  }
}

.mobile-menu__btn {
  margin: 0 auto;

  @media screen and (min-width: $tablet-width) {
    display: none;
  }
}