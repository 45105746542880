.footer {
  position: relative;
  // background-color: $accent-color;
  padding-bottom: 62px;

  @media screen and (min-width: $tablet-width) {
    padding-bottom: 13px;
  }

  @media screen and (min-width: $desktop-width) {
    padding-bottom: 10px;
  }

  &::after {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);

    content: '';
    display: block;
    height: 1px;
    width: 100%;
    max-width: 1168px;
    background-color: #e1e1e1;

    @media screen and (min-width: $tablet-width) {
      bottom: 53px;
    }

    @media screen and (min-width: $desktop-width) {
      bottom: 45px;
    }
  }
}

.footer__container {
  margin-bottom: 56px;

  @media screen and (min-width: $tablet-width) {
    margin-bottom: 46px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: $desktop-width) {
    margin-bottom: 66px;
  }
}

.footer__title {
  max-width: 260px;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.22;
  letter-spacing: normal;
  text-transform: uppercase;
  color: $light-text-color;

  @media screen and (min-width: $tablet-width) {
    max-width: 352px;
    font-size: 2.6rem;
    line-height: 1.23;
    letter-spacing: 0.04em;
  }

  @media screen and (min-width: $desktop-width) {
    max-width: none;
    font-weight: 400;
    font-size: 3rem;
  }

  > span {
    margin-top: 14px;
    display: block;
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 1.21;
    letter-spacing: 0.04em;

    @media screen and (min-width: $tablet-width) {
      margin-top: 2px;
      font-size: 4.8rem;
      line-height: 1.23;
      letter-spacing: 0.06em;
    }

    @media screen and (min-width: $desktop-width) {
      margin-top: 14px;
      font-size: 5.8rem;
      line-height: 1.22;
    }
  }
}

.footer__social-list {
  margin-top: 36px;
  margin-bottom: 38px;
  display: flex;
  justify-content: flex-end;
  gap: 29px;

  @media screen and (min-width: $tablet-width) {
    margin-top: 68px;
    margin-bottom: 32px;
    gap: 30px;
  }

  @media screen and (min-width: $desktop-width) {
    margin-top: 12px;
    margin-bottom: 40px;
  }
}

.footer__social-item {
  @include transition(transform, $transition-duration, $transition-timing-function);
  border-radius: 50%;
  overflow: hidden;
  transform: scale(1);

  &:focus-within {
    transform: scale(1.12);
  }
}

.footer__social-link {
  @include transition(color, $transition-duration, $transition-timing-function);
  color: $accent-color;

  &:hover,
  &:focus {
    outline: none;
    color: $light-text-color;
  }
}

.footer__social-icon {
  width: 32px;
  height: 32px;
  display: block;
  fill: currentColor;

  @media screen and (min-width: $tablet-width) {
    width: 34px;
    height: 34px;
  }
}

.footer__contact {
  text-align: right;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.86;
  letter-spacing: normal;

  @media screen and (min-width: $tablet-width) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: $desktop-width) {
    font-size: 1.6rem;
    letter-spacing: 0.02em;
  }

  > .footer__contact-link {
    color: $light-text-color;
  }
}

.footer__copyright {
  margin: 0 auto;
  text-align: center;
  color: $copyright-text-color;
  font-weight: 700;
  line-height: 1.52;
  letter-spacing: normal;

  @media screen and (max-width: 399px) {
    max-width: 165px;
  }

  @media screen and (min-width: $tablet-width) {
    letter-spacing: 0.02em;
    line-height: 1.86;
  }

  @media screen and (min-width: $desktop-width) {
    font-size: 1.2rem;
  }
}
