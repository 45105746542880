html {
  font-size: $default-font-size;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
p,
ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}