.arrow-up {
  position: fixed;
  background-color: $light-text-color;
  right: 30px;
  bottom: 95px;
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  opacity: 0.7;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transform: scale(1);
  transition: color $transition-duration $transition-timing-function,
    transform $transition-duration $transition-timing-function,
    opacity $transition-duration $transition-timing-function;

  &:hover {
    color: $primary-color;
    transform: scale(1.1);
    opacity: 1;
  }
}

.arrow-up.hide {
  display: none;
}

.arrow-up-icon {
  transform: rotate(-90deg);
  fill: currentColor;
}