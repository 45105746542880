.about {
  padding-top: 60px;
  // background-color: $accent-color;
  color: $light-text-color;
}

// ЗАГОЛОВКИ
.about__title-label {
  margin-bottom: 12px;

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    letter-spacing: $default-letter-spacing;
  }
}

.about__title {
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 35px;
  color: $light-text-color;

  // ТАБЛЕТ
  @media screen and (min-width: $tablet-width) {
    max-width: 100%;
    margin-bottom: 72px;
    letter-spacing: 0.06em;
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    margin-bottom: 84px;
  }
}

// КАРТИНКА+ТЕКСТ+КНОПКА
.about__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  // ТАБЛЕТ
  @media screen and (min-width: $tablet-width) {
    flex-wrap: nowrap;
    align-items: center;
    gap: 32px;
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    justify-content: left;
    gap: 108px;
  }
}

.about__image-wrap {
  max-width: 335px;

  // АДАПТИВ ПІД 480PX
  @media screen and (min-width: $phone-width) {
    max-width: 335px;
  }

  // ТАБЛЕТ
  @media screen and (min-width: $tablet-width) {
    max-width: calc((100% - 32px) / 2);
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    max-width: 490px;
    width: 490px;
  }
}

// КОРОВА
.about__image {
  box-shadow: 0 -21.82px $primary-color;
  margin-top: 21.82px;
  // box-shadow+margin-top - комбінація для того,
  // щоб враховувати розмір тіні у розмір самого зображення.
  // Це щоб центрування не руйнувалось.

  border-radius: 1038.58px;
  width: 100%;

  // ТАБЛЕТ
  @media screen and (min-width: $tablet-width) {
    box-shadow: 0 -25.88px $primary-color;
    margin-top: 25.88px;
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    box-shadow: 0 -39px $primary-color;
    margin-top: 39px;
  }
}

.about__btn-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  gap: 14px;

  // АДАПТИВ ПІД 480PX
  @media screen and (min-width: $phone-width) {
    padding-left: 0;
    padding-right: 0;
    max-width: 380px;
  }

  // ТАБЛЕТ
  @media screen and (min-width: $tablet-width) {
    max-width: calc((100% - 32px) / 2);
    text-align: left;
    align-items: baseline;
    gap: 0;
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    max-width: 463px;
  }
}

// ТЕКСТ
.about__paragraph {
  color: $light-text-color;
  font-size: 1.2rem;

  &:nth-child(2) {
    @media screen and (max-width: 479px) {
    line-height: 1.63;
    letter-spacing: 0.020em;
    }
  }

  &:first-child {
    margin-bottom: 4px;
  }

  // МОБ.ВЕРСІЯ
  &:last-child {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  // ТАБЛЕТ
  &:not(:last-child) {
    @media screen and (min-width: $tablet-width) {
      margin-bottom: 8px;
    }
  }

  &:last-child {
    @media screen and (min-width: $tablet-width) {
      margin-bottom: 48px;
    }
  }

  // ДЕСКТОП
  @media screen and (min-width: $desktop-width) {
    font-size: 1.6rem;

    &:not(:first-child) {
        font-size: 1.4rem;
      }
  }
}

// КНОПКА
.about__btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

// МОДАЛКА
.about-modal__title {
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.about__video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.about__video-descr {
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0em;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
}
