.about-modal__title {
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.about__video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.about__video-descr {
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0em;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
}