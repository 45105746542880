.btn {
  display: inline-block;
  padding: 10px 32px;

  font-family: $main-font-family;
  font-weight: $semibold-text;
  font-size: 1.4rem;
  line-height: 1.29;

  color: $default-text-color;
  border: 2px solid transparent;
  border-radius: 1rem;

  outline: none;

  cursor: pointer;
}

.btn--underlined {
  color: $primary-color;
  text-decoration: underline;

  transition: color $transition-duration $transition-timing-function;

  &:hover {
    color: $hover-color;
  }

  &:focus {
    color: $light-text-color;
  }

  &:disabled {
    color: $disabled-color;
  }
}

.btn--primary {
  background-color: $primary-color;

  transition: color $transition-duration $transition-timing-function,
              background-color $transition-duration $transition-timing-function;

  &:hover {
    background-color: $hover-color;
  }

  &:focus {
    color: $light-text-color;
    background-color: $hover-color;
  }

  &:disabled {
    background-color: $disabled-color;
  }

  svg {
    fill: currentColor;
    pointer-events: none;
  }
}

.btn--border {
  border-color: $primary-color;
  background-color: transparent;

  transition: color $transition-duration $transition-timing-function,
              border-color $transition-duration $transition-timing-function;

  &:hover {
    border-color: $hover-color;
  }

  &:focus {
    color: $light-text-color;
    border-color: $hover-color;
  }

  &:disabled {
    border-color: $disabled-color;
  }

  svg {
    fill: currentColor;
    pointer-events: none;
  }
}
